// Bootstrap, Font Awesome, Glyphicons
@import "bootstrap-3.3.6/bootstrap";
@import "font-awesome-4.4.0/font-awesome";
@import "others/glyphicons";
@import "others/glyphicons-filetypes";

// Simple-line icons, uniform, bootstrap-switch
@import "others/simple-line-icons";
//@import "others/uniform.default.less";
//@import "others/bootstrap-switch";
//@import "others/icheck-minimal";

// Metronic CSS
@import "metronic/components-md-4.5.5.less";
@import "metronic/plugins-md-4.5.5.less";
@import "metronic/layout-4.5.5.less";
@import "metronic/light2-4.5.5.less";

.note p {
  margin-bottom: 10px !important;
}

.vspace-5, .vspace-10, .vspace-15, .vspace-20 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
}
.vspace-5 {
  margin: 5px 0 4px;
}
.vspace-10 {
  margin: 10px 0 9px;
}
.vspace-15 {
  margin: 15px 0 14px;
}
.vspace-20 {
  margin: 20px 0 19px;
}

// BundleRabbit link stuff
.bundlerabbit-ad .wrapper {
  margin-top: 20px;
  text-align: center;
}
.bundlerabbit-ad .link-box {
  padding: 10px;
  background-color:#4f5e81;
}
.bundlerabbit-ad .link-box:hover {
  background-color:#65769e;
}
.bundlerabbit-ad .link-box a {
  color: #fff;
}
.bundlerabbit-ad .link-box a:hover {
  text-decoration: none;
}
.bundlerabbit-ad .link-box img {
  display:inline-block;
  margin: 10px auto 0px;
}
.bundlerabbit-ad .link-box .motto {
  font-size:12px;
  margin: 3px auto 10px;
}


